import React from "react"

import { Heading, Body } from "@components/ui/Typography/Typography"
import "./HomeProjectsList.scss"
import Separator from "@components/ui/Separator/Separator"
import ProjectItem from "@components/ProjectItem/ProjectItem"

import chefclubPreviewImg from "./assets/chefclub-preview.png"
import clickandcarePreviewImg from "./assets/clickandcare-preview.png"
import placemeetPreviewImg from "./assets/placemeet-preview.png"
import BubbleCTA from "@components/BubbleCTA/BubbleCTA"
import Button from "@components/ui/Button/Button"
import { IGatsbyImageData } from "gatsby-plugin-image"
import { getGatsbyImage } from "@utils/images"

interface PropTypes {
  projectImages?: { gatsbyImageData: IGatsbyImageData }[]
}

const HomeProjectsList = ({ projectImages }: PropTypes) => {
  return (
    <section className="our-projects" id="our-projects">
      <Heading variant="h1" noMargin>
        View Our Most Recent Projects
      </Heading>
      <Body noMargin>
        Check out a few of our latest projects where we were effective in
        strengthening our clients’ development teams.
      </Body>

      <div className="our-projects-list">
        <ProjectItem
          pills={["Web application", "Node.js"]}
          description={
            "A digital cooking brand that has rapidly grown in Europe, the United States, Latin America and China thanks to the free distribution of high quality content."
          }
          projectName={"Chefclub"}
          button={{ link: "https://www.chefclub.tv" }}
          fallbackSimplePicture={chefclubPreviewImg}
          gatsbyImage={getGatsbyImage("chefclub", projectImages)}
        />
        <ProjectItem
          reverseOrder
          pills={["Web application", "Node.js"]}
          description={
            "Click & Care is a startup that has raised funds, developing a web & mobile support platform for all actors wishing to set up a home help service, relieving them of recruitment and administrative costs."
          }
          projectName={"Click & Care"}
          button={{ link: "https://clickandcare.fr" }}
          fallbackSimplePicture={clickandcarePreviewImg}
          gatsbyImage={getGatsbyImage("clickandcare", projectImages)}
        />
        <ProjectItem
          pills={["Web application", "Node.js"]}
          description={
            "It's a space dedicated to your online business events. A human experience, inspired by the real world."
          }
          projectName={"Placemeet"}
          button={{ link: "https://placemeet.fr" }}
          fallbackSimplePicture={placemeetPreviewImg}
          gatsbyImage={getGatsbyImage("placemeet", projectImages)}
        />
      </div>

      <BubbleCTA userId="alexandre" className="our-projects-bubble-cta">
        <Body>Want to discover more projects ?</Body>
        <Button variant="tertiary" to="/projects">
          Our projects
        </Button>
      </BubbleCTA>

      <Separator />
    </section>
  )
}

export default HomeProjectsList
