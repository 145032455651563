import * as React from "react"
import HomeHero from "@landingsParts/Home/HomeHero/HomeHero"
import HomeSocialProof from "@landingsParts/Home/HomeSocialProof/HomeSocialProof"
import SEO from "@components/SEO/SEO"
import Layout from "@components/layout/layout"
import HomeTechnologiesUsed from "@landingsParts/Home/HomeTechnologiesUsed/HomeTechnologiesUsed"
import HomeProjectsList from "@landingsParts/Home/HomeProjectsList/HomeProjectsList"
import HomeBlog from "@landingsParts/Home/HomeBlog/HomeBlog"
import Awards from "@landingsParts/Home/HomeAwards/HomeAwards"
import { graphql } from "gatsby"
import { IGatsbyImageData } from "gatsby-plugin-image"

// markup
const HomePage = ({ data }: HomePageQuery) => {
  const projectsImages = data.allImages.nodes
  return (
    <main>
      <SEO
        title="Kezios - Développement web"
        lang="fr"
        description={
          "We are a French agency composed by software engineer. We believe in the potential of startups that change the world, by giving them access to the right technology, we are sure they will succeed"
        }
      />
      <title>Kezios</title>
      <Layout>
        <HomeHero />
        <HomeSocialProof />
        <HomeTechnologiesUsed />
        <Awards />
        <HomeProjectsList projectImages={projectsImages} />
        <HomeBlog />
      </Layout>
    </main>
  )
}

interface HomePageQuery {
  data: {
    allImages: {
      nodes: { gatsbyImageData: IGatsbyImageData }[]
    }
  }
}

export const query = graphql`
  query HomePageQuery {
    allImages: allImageSharp {
      nodes {
        gatsbyImageData
      }
    }
  }
`
export default HomePage
