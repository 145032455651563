import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import "./HomeSocialProof.scss"
import { Body, Heading } from "@components/ui/Typography/Typography"
import Separator from "@components/ui/Separator/Separator"

const HomeSocialProof = () => {
  return (
    <section className="social-proof">
      <Heading variant="h3" className="social-proof-heading" bold>
        They trusted us
      </Heading>

      <Body className="social-proof-body">
        They Love Our Work (and we love working with them)
      </Body>

      <div className="social-proof-flex">
        <StaticImage
          className="image-social-proof"
          src="./assets/Logo_ChefClub.png"
          alt="Chefclub"
          objectFit="contain"
          placeholder="blurred"
          quality={100}
        />
        <StaticImage
          className="image-social-proof"
          src="./assets/Logo_Placemeet.png"
          alt="Placemeet"
          objectFit="contain"
          placeholder="blurred"
          quality={100}
        />
        <StaticImage
          className="image-social-proof"
          src="./assets/Logo_Curecall.png"
          alt="Curecall"
          objectFit="contain"
          placeholder="blurred"
          quality={100}
        />
        <StaticImage
          className="image-social-proof"
          src="./assets/Logo_ToolPad.png"
          alt="ToolPad"
          objectFit="contain"
          placeholder="blurred"
          quality={100}
        />
        <StaticImage
          className="image-social-proof"
          src="./assets/Logo_SportsDynamics.png"
          alt="Sports Dynamics"
          objectFit="contain"
          placeholder="blurred"
          quality={100}
        />
        <StaticImage
          className="image-social-proof"
          src="./assets/Logo_Visite+.png"
          alt="Visite"
          objectFit="contain"
          placeholder="blurred"
          quality={100}
        />
        <StaticImage
          className="image-social-proof"
          src="./assets/Logo_Click&Care.png"
          alt="Click&Care"
          objectFit="contain"
          placeholder="blurred"
          quality={100}
        />
      </div>
      <Separator />
    </section>
  )
}

export default HomeSocialProof
