import AvatarGroup from "@components/AvatarGroup/AvatarGroup"
import CenteredContent from "@components/CenteredContent/CenteredContent"
import Button from "@components/ui/Button/Button"
import React from "react"

import "./HomeHero.scss"

const HomeHero = () => {
  return (
    <section className="hero-container">
      <CenteredContent>
        <div className="hero-avatars">
          <AvatarGroup variant="large" />
        </div>

        <h1 className="hero-heading">
          We Are Kezios:
          <br />
          We reinforce your dev team
        </h1>

        <div className="hero-ctas">
          <Button variant="primary" to="/#contact">
            Contact us
          </Button>
          <div className="hero-ctas-separator" />
          <Button variant="secondary" to="/projects">
            Our projects
          </Button>
        </div>
      </CenteredContent>
    </section>
  )
}

export default HomeHero
