import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import "./HomeTechnologiesUsed.scss"
import { Body, Heading } from "@components/ui/Typography/Typography"
import Separator from "@components/ui/Separator/Separator"

const HomeTechnologiesUsed = () => {
  return (
    <section className="technologies-used">
      <Heading variant="h1" noMargin>
        The technologies we use
      </Heading>
      <Body noMargin className="technologies-used-body">
        We seek to provide our clients with the best work possible, that's why
        we use the best and latest technologies, including GPT-3 / GPT-4 /
        ChatGPT, to develop powerful web and mobile applications.
      </Body>

      <div className="technologies-used-flex">
        <div className="technology-block">
          <StaticImage
            className="image-social-technologies-used"
            src="./assets/Logo_OpenAI.png"
            alt="GPT-4"
            objectFit="contain"
            placeholder="blurred"
          />
        </div>
        <div className="technology-block">
          <StaticImage
            className="image-social-technologies-used"
            src="./assets/Logo_NextJS.png"
            alt="Next.js"
            objectFit="contain"
            placeholder="blurred"
          />
        </div>
        <div className="technology-block">
          <StaticImage
            className="image-social-technologies-used"
            src="./assets/Logo_React.png"
            alt="React.js"
            objectFit="contain"
            placeholder="blurred"
          />
        </div>
        <div className="technology-block">
          <StaticImage
            className="image-social-technologies-used"
            src="./assets/Logo_TypeScript.png"
            alt="Typescript"
            objectFit="contain"
            placeholder="blurred"
          />
        </div>
        <div className="technology-block">
          <StaticImage
            className="image-social-technologies-used"
            src="./assets/Logo_NodeJS.png"
            alt="Node.js"
            objectFit="contain"
            placeholder="blurred"
          />
        </div>
        <div className="technology-block">
          <StaticImage
            className="image-social-technologies-used"
            src="./assets/Logo_Gatsby.png"
            alt="Gatsby.js"
            objectFit="contain"
            placeholder="blurred"
          />
        </div>
      </div>
    </section>
  )
}

export default HomeTechnologiesUsed
