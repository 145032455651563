import React from "react"
import "./HomeBlog.scss"
import { Body, Heading } from "@components/ui/Typography/Typography"
import { graphql, useStaticQuery } from "gatsby"
import ArticleCard from "@components/ArticleCard/ArticleCard"
import BubbleCTA from "@components/BubbleCTA/BubbleCTA"
import Button from "@components/ui/Button/Button"

const HomeBlog = () => {
  const {
    allDevToArticle: { nodes: articles },
  } = useStaticQuery(graphql`
    query ArticleQuery {
      allDevToArticle(
        limit: 3
        sort: { fields: published_timestamp, order: DESC }
      ) {
        nodes {
          title
          slug
          description
          reading_time_minutes
          published_timestamp
          tags
          social_image
          user {
            username
          }
        }
      }
    }
  `)

  return (
    <section className="home-blog">
      <Heading variant="h1" noMargin>
        Blog
      </Heading>
      <Body className="home-blog-description">
        Discover new and informative articles written by the Kezios team.
        Articles cover performance, architecture, code best practices, and much
        more!
      </Body>

      <div className="home-blog-article-container">
        {articles.map((article: any) => (
          <ArticleCard {...article} key={article.key} />
        ))}
      </div>

      <BubbleCTA userId="adrian" className="home-blog-bubble-cta">
        <Body>There are other articles !</Body>
        <Button variant="tertiary" to={"/articles"}>
          Our articles
        </Button>
      </BubbleCTA>
    </section>
  )
}

export default HomeBlog
